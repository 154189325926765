@import '../../sassStyles/global-imports';

.btn {
  display: inline-block;
  //padding: 0.625em 0.750em;
  border-radius: 0.3125em;
  cursor:pointer;
  width: 100%;
  font-weight: $regular;
  font-size: $body-md;
    text: {
      decoration: none;
      align: center;
    }
  @include trans(0.4s ease-in-out);

  @include smMinBreakPoint {
    width: initial;
  }
}

.btn-md {
  min-width: 5rem !important;
}

.btn-lg {
  min-width: 10rem !important;
}

.btn-xl {
  width: 100% !important;
  font-size: $body-lg;
}

.fa-icon { //used a lot, how to make global?
  width: 1.2em !important;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.375em;
  margin-bottom: 0.2em;
}

.btn-margin {
  margin: 0.5em 0;

  @include smMinBreakPoint {
    margin: 1.5em 0;
  }
}

.btn-right {
  margin-left: auto;
}
.btn-left {
  margin-right: auto;
}

.btn-lnk {
  color: $color-primary-red;
  outline: none;
  background: transparent;
  border: none;
  text-decoration: underline;
  background: none!important;
  padding: 0!important;
  cursor: pointer;


 &:active, &:hover {
   color: darken($color-primary-red, 6%);
   outline: none;
   background: transparent;
   border: none;
   text-decoration: underline;
 }
}

// Full colour
