@import '../../sassStyles/global-imports';

.chart-text {
  padding-top: 2rem;
  margin-bottom: -1.5rem;
  margin-left: auto;

  p {
    color: $color-primary-blue;
  }
}

.usage-select {
  width: 20rem;
}
