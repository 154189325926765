/**
 * Shows how you can use CSS to style your Element's container.
 * These classes are added to your Stripe Element by default.
 * You can override these classNames by using the options passed
 * to the CardElement component.
 * https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
 */
.StripeElement {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
.StripeElement--focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
.StripeElement--invalid {
    border-color: var(--danger);
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}