@import '../../sassStyles/global-imports';

.password-forget-link {
  margin-left: 1rem;
}

@media only screen and (max-width: 768px) {
  .password-forget-link {
    margin-left: 0;
  }
}

.password-forget-ctr {
  display: flex;
}

.password-forget-ctr .col .row {
  padding-bottom: 1rem;
}

.password-ctr {
  margin: 2.5rem auto;
  max-width: 31.25rem;
  padding: 1.25rem;
}