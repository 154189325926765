@import './mixins';
@import './variables';

h1 {
  font-size: $heading;
  color: $color-darkblue;
  font-weight: $regular;
  margin-bottom: 0.5rem;
  @include smMinBreakPoint {
    margin-bottom: 1.5rem;
  }
}

h2 {
  color: $color-darkblue;
  font-size: $subheading;
  font-weight: $regular;
}

p {
  color: $color-darkgrey;
  font-size: 0.9rem;
}