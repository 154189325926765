@import '../../sassStyles/global-imports';

main {
  display: block;
  padding: 1em 1em 2em 1em;
  width: auto;  /* Firefox will set width as auto */
  min-height: 100vh;
  transition: opacity 1250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-top: 0;

  @include smMinBreakPoint {
    margin: 0 auto;
    min-width: calc(100% - 200px);
  }

  @include mdMinBreakPoint {
    margin: auto;
    margin-left: 0;
    padding: 1em 4em 0 1em;
  }

  @include lgMinBreakPoint {
    width: 1000px;
    padding: 3em 12.5em 0 2em;
  }

  @include xxlMinBreakPoint {
    margin-left: auto;
    max-width: 1400px;
    min-width: 1400px;
  }
}