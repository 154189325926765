@import '../../sassStyles/global-imports';

form {
  .col-form-label:first-child, .form-fieldnames .label {
    color: $color-darkblue;
    font-weight: $regular;
  }

  a {
    text-decoration: underline;
  }
}

.form-validation-error {
  color: $color-primary-red;
  margin: -0.1rem 0 -1rem 0;
}

.block-display {
  display: block;
}