@import '../../sassStyles/global-imports';

.ctr {
  margin-bottom: 1.5rem;

  @include mdMinBreakPoint {
    border-radius: 5px;
  }

  h2 {
    color: $color-darkgrey;
    font: {
      weight: $medium;
      size: $subheading;
    }
    padding: 0.5rem 0;

    @include mdMinBreakPoint {
      border-radius: 5px 5px 0 0;
      font: {
        weight: $regular;
        size: $body-md;
      }
    }
  }

  h3 {
    font-weight: $light;
    font-size: $body-md;
    color: $color-darkblue;

    @include mdMinBreakPoint {
      font: {
        weight: $regular;
        size: $body-md;
      }
    }
  }

  .form-group, p {
    color: $color-darkgrey;
    font-weight: $light;
    padding-bottom: 0.2rem;

    @include mdMinBreakPoint {
      font-size: $body-sm;
    }
  }

  .ctr__info-text {
    font-size: $body-sm;
    color: $color-grey;

    @include mdMinBreakPoint {
      padding-bottom: 1em;
      margin: 0;
    }
  }

  .input-fields {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .form-fieldnames .col-form-label:first-child, form  .col-form-label:first-child, .form-fieldnames label, label  {
    color: $color-darkblue;
    font-weight: $regular;
    @include mdMinBreakPoint {
      font-size: $body-sm;
    }
  }

  .form-fieldnames .col-form-label:nth-child(2), .form-group input, .form-check-label .form-group div p {
    color: $color-darkgrey;
    font-weight: $light;
    @include mdMinBreakPoint {
      font-size: $body-sm;
    }
  }

  .form-group div p {
    padding-left: 0;
    margin-top: 0.3rem;
    @include mdMinBreakPoint {
      margin-top: 0;
      padding-left: initial;
    }
  }

  .form-control {
    font-size: $body-sm;
  }

  .ctr__section-spacing {
    @include mdMinBreakPoint {
      padding: 0 1em 1em 1em;
    }
  }

  .hidden-heading {
    display: none;
    @include smMinBreakPoint {
      display: block;
    }
  }

}

.ctr-bordered {
  @include mdMinBreakPoint {
    border: 1px solid $color-greyishblue-light;
  }

  h2 {
    @include mdMinBreakPoint {
      background-color: $color-greyishblue;
      padding: 1em;
    }
  }

  .form-group, p, input {
    @include mdMinBreakPoint {
      padding: 0.1em 1em;
    }
  }

  .form-fieldnames .col-form-label:first-child, form  .col-form-label:first-child, .form-fieldnames label, label, input  {
    @include mdMinBreakPoint {
      padding: 0.1em 1em;
    }
  }
}

