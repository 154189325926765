@import '../../sassStyles/global-imports';


.main-modal .verify-code-form .row .btn:first-child, .main-modal .add-phone-form .row .btn:first-child {
  margin-right: 0.5rem;
}

.confirmation-ctr h2 {
  padding: 1.2rem 0 2rem 0;
  font-size: 1.2em;
}

.confirmation-ctr .container:first-child {
  padding-top: 0.5rem;
}
