@import '../../sassStyles/global-imports';

/* Stripe Element */

.add-stripe-wrapper {
  padding: 1rem;

  label {
    padding: 0;
  }

  .form-group {
    width: 100%;
    padding: 0;
  }

   .ctr-add-stripe {
    padding: 0;

    .row {
      margin-bottom: 1em;
    }

    .logos {
      flex-wrap: wrap;
      text-align: center;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-bottom: 0.6rem;

      @include mdMinBreakPoint {
        padding-bottom: 0;
      }

      .div {
        width: fit-content;
      }
    }
  }

  input.card-name::placeholder {
    color: #aab7c4;
    font-family: '"Rubik",Helvetica Neue, Helvetica', sans-serif;
    font-size: 0.9rem;
  }
}

.billing-text {
  color: $color-grey;

  img {
    max-width: 2.1rem;
    position: relative;
  }

  .col-md-auto {
    max-width: fit-content;
  }
}





