/* */

@import './variables';

// Breakpoints
@mixin smMinBreakPoint {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin mdMinBreakPoint {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin lgMinBreakPoint {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xlMinBreakPoint {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin xxlMinBreakPoint {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin customMinBreakPoint($size) {
  @media (min-width: $size+'px') {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+'px') {
    @content;
  }
}

// Padding and margin

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25em;
    /* margin #{$margin} */
    .m#{$i}  {margin: $margin;}
    .ml#{$i} {margin-left: $margin;}
    .mr#{$i} {margin-right: $margin;}
    .mt#{$i} {margin-top: $margin;}
    .mb#{$i} {margin-bottom: $margin;}
    .mx#{$i} {margin-left: $margin; margin-right: $margin;}
    .my#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;


@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {padding: $padding;}
    .pl#{$i} {padding-left: $padding;}
    .pr#{$i} {padding-right: $padding;}
    .pt#{$i} {padding-top: $padding;}
    .pb#{$i} {padding-bottom: $padding;}
    .px#{$i} {padding-left: $padding; padding-right: $padding;}
    .py#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;

@mixin trans($val...) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  transition: $val;
}

// Badge, button and alert colours

@mixin variants {
  @each $name, $color in $colors {
      .badge-#{$name} {
      &-filled {
        background-color: $color;
        color: white;
      }
      &-outline {
        background-color: white;
        box-shadow: inset 0 0 0 0.0625rem $color;
        color: $color;
      }
    }
    .#{$name} {
        background-color: $color;
        color: white;
        &:hover {
          background-color: darken($color, 6%);
          color: $white;
        }
        &:active {
        }
      &-outline {
        background-color: white;
        box-shadow: inset 0 0 0 0.0625rem $color;
        border: 1px solid $color;
        color: $color;
        &:hover {
          background-color: darken($color, 6%);
          border: 1px solid darken($color, 6%);
          color: $white;
        }
        &:active {
        }
      }
    }
  }
}
@include variants;

// Alert mixins

@each $name, $color in $colors {
  .custom-alert__#{$name} {
      padding: 0.75rem 1.25rem;
      border-radius: 0.25rem;
      font-size: 0.9rem;
      background-color: lighten($color, 48%);
      border: 1px solid lighten($color, 30%);
      color: $color;
    &-text {
      color: $color;
      font-size: 0.9rem;
    }
  }
}




