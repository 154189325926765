@import '../../sassStyles/global-imports';

.security-settings-mfa__text {
  margin-top: 0.5rem;
  @include lgMinBreakPoint {
    margin-top: 0.1rem;
  }
}

.security-settings-mfa__text p {
  margin-bottom: initial;
  margin-right: 1rem;
  @include lgMinBreakPoint {
    margin-top: 0.1rem;
    margin-left: initial;
  }
}