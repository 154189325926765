@import '../../sassStyles/global-imports';

.signin-ctr__bg, .wide-bg {
  display: flex;
  padding: 0;
  height: 100vh;
  margin-left: 0;
  width: auto;  /* Firefox will set width as auto */
}

.signin-ctr__bg form {
  @include smMinBreakPoint {
    min-width: 28rem;
  }
}

.signin-ctr__bg > img {
  background-repeat: no-repeat;
  //background-color: $primary;
  display: none;
  margin: auto;

  @include xxlMinBreakPoint {
    display: initial;
    margin: initial;
  }
}

.signin-ctr__bg > div:nth-child(2) {
  margin: auto;
  padding: 1rem 2rem;
  @include lgMinBreakPoint {
    padding-left: 10rem;
  }
}

.signin-ctr__btns {
  flex-direction: column;
  @include smMinBreakPoint {
    flex-direction: initial;
    justify-content: space-between;
  }

  p {
    margin-right: 0.2rem;
  }
}

.signin-ctr__btns span {
  display: flex;
}
