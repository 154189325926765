@import '../../sassStyles/global-imports';

.key-btns {
  @include smMinBreakPoint {
    padding: 1rem;
  }
}

.key-created__msg {
  font-weight: $medium !important;
  font-size: $body-lg;
  padding: 1rem 0;
  margin: 0;
  @include smMinBreakPoint {
    display: flex;
    justify-content: space-around;
  }
}

.key-created__line {
  border-top: 1px solid $color-greyishblue-light;
  padding: 0;
  margin-top: 0;
  @include smMinBreakPoint {
    padding: 1rem;
    margin: 0 1rem;
  }
}