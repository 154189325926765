@import '../../sassStyles/global-imports';

section {
  background-color: $color-greyishblue;
  margin: 0;
  //padding-top: 12px; //needs a more elegant solution, measure it out okay
  border-right: 1px solid $color-greyishblue-light;
  font-size: $body-sm;

  @include mdMinBreakPoint {
    min-width: 200px;
  }

  .nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: sticky !important;

    &:first-child {
      padding-top: 12px;
    }

    a {
      position: relative;
      display: block;
      padding: 0.75em 1.2em;
      text-decoration: none;
      color: $color-grey;

      @include mdMinBreakPoint {
        padding: 0.75em 2em;
      }
    }

    a.active {
      color: $color-primary-blue;
    }

    a.active, a:hover  {
      background-color: $color-greyishblue-dark;
      text-decoration: none;
      color: $color-primary-blue;
    }

    .icon { //used a lot, how to make global?
      width: 1.2em !important;
      display: inline-block;
      vertical-align: middle;

      @include mdMinBreakPoint {
        width: 1.2em !important;
        margin-right: 0.8em;
      }
    }
  }

  span {
    vertical-align: middle;
    display: none;

    @include mdMinBreakPoint {
      display: initial;
    }
  }
}


