@import '../../sassStyles/global-imports';

.premium-banner {
  text-align: center;
  margin: 1rem;
}

.progress-bar-ctr {
  justify-content: space-between;
  flex-direction: column;
  @include mdMinBreakPoint {
    flex-direction: row;
    margin-left: 1rem;
  }
}

.progress-bar-ctr div:nth-child(1) {
  width: 100%;
  @include mdMinBreakPoint {
    width: 80%;
  }
}

.premium-ctr {
  justify-content: center;
  align-items: center;
  vertical-align: text-bottom;
  @include smMinBreakPoint {
    justify-content: right;
    margin-right: 0;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
  }
    a {
      margin: 0 1rem;
      @include smMinBreakPoint {
        margin: 0 0 0.5rem 1rem;
      }
    }

    h3 {
      font-size: $body-md;
      @include mdMinBreakPoint {
        margin-right: 0.5rem;
        font-size: $body-md;

    }
  }
}

