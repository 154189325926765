@import '../../sassStyles/global-imports';

/* Space Premium background */

.gradient-bg {
  background: #115CA6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #115CA6, #FFFFFF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #115CA6, #FFFFFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  max-width: 100%;
  padding: 3rem 0;
  width: auto;
  min-width: 100%;
}

.space-premium {
  border: 1px solid $color-greyishblue-light;
  padding: 1rem 2rem 3rem 2rem !important;
  background-color: $white;
  border-radius: 5px;
  max-width: 32rem !important;
  color: $color-darkblue;
  font-size: 0.9rem;
}

/* Space Premium Progressbar */

.multistep-bar {
  display: flex;
  text-align:center;
  flex-wrap: nowrap;
  margin: -1rem 0 -0.2rem 0;
}

.progressbar {
  width: 100%;
}

.progressbar a.nav-link {
  color: $color-primary-red
}

.completed a.nav-link {
  color: $color-primary-blue;
}

.progress-icon {
  position: absolute;
  font-family: "Arial Black", sans-serif;
  font-size: 1.2rem;
  margin-left: -1.31rem;
  color: white;
}

.close-icon {
  color: $color-lightgrey;
  margin-top: -4rem;
  margin-right:-1.7rem;
}

.check-icon {
  background-color: white;
}

.progressbar a.nav-link {
  display: inline-block;
  content: "";
  border-bottom: 2px solid $color-primary-red;
  z-index: -2;
  margin: 0 auto 20px auto;
  width: 100%;
  height: 1.5rem;
}

.completed a.nav-link {
  border-bottom: 2px solid $color-primary-blue;
}

/* Space Premium General styles */

.space-premium-ctr-landing, .space-premium-forms-ctr {
  padding: 1.5rem 0 0 0;
  min-height: 34rem;
}

.space-premium-ctr-landing h1 {
  text-align: center;
  font-size: 2.5rem;
  color: $color-primary-blue;
  font-weight: 600;
  padding-top: 0.2rem;
}

.space-premium-ctr-landing h2 {
  text-align: center;
  color: $color-grey;
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
}

.price-ctr p {
  font-size: 1.8rem;
  text-align: center;
  color: $color-grey;
  padding-bottom: 0.2rem;
}

.space-premium-ctr-landing div p span {
  font-size: 3rem;
  text-align: center;
  color: $color-primary-blue;
  font-weight: 600;
}

.space-premium-ctr-landing ul {
  padding-left: 5rem;
  list-style-type: none;
  font-size: 1.1rem;
  color: $color-grey;
  line-height: 2.1;
}

.space-premium-ctr-landing p:nth-child(5) {
  font-size: 0.8rem;
  color: $color-grey;
  margin-top: -0.5rem;
  padding-left: 5rem;
}

.space-premium-check-icons {
  color: $color-primary-blue;
}

.space-premium-btns {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.space-premium-forms-ctr h2 {
  text-align: left;
  color: $color-darkblue;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
}

.space-premium-forms-ctr h2:nth-child(2) {
  padding-top: 0;
}

.space-premium-forms-ctr .add-stripe-wrapper {
  padding: 0;
}

.space-premium-forms-ctr .alert-info {
  font-size: 0.7rem;
}

.space-premium-forms-ctr .billing-text {
  font-size: 0.6rem !important;
}

.space-premium-forms-ctr .billing-text img {
  position: relative;
  padding-bottom: 0.5rem;
}

.space-premium-check-icon-success {
  color: $color-primary-blue;
}

.space-premium-success-ctr {
  height: 36rem;
  padding-top: 6rem;
  text-align: center;
}

.space-premium-success-ctr h1 {
  padding-top: 0.9rem;
  padding-bottom: 2rem;
  font-size: 2.1rem;
  color: $color-darkblue;
}

.space-premium-success-ctr p {
  font-size: 1.1rem;
  padding-bottom: 2rem;
  color: $color-grey;
}

.alert-size {
  font-size: 0.7rem;
  padding: 0.05rem;
  margin: -1.5rem 0 0.3rem 0;
}

.inactive-link {
  cursor: default;
}

/* Space Premium mobile views */

@media only screen and (max-width: 600px) {
  .gradient-bg {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

@media only screen and (max-width: 560px) {
  .gradient-bg {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 512px) {
  .gradient-bg {
    padding: 0;
  }

  .space-premium {
    min-height: 100vh;
    border-radius: 0;
  }

  .space-premium-ctr-landing ul {
    padding-left: 2rem;
  }

  .space-premium-ctr-landing p:nth-child(5) {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 380px) {

  .space-premium-ctr-landing, .space-premium-forms-ctr {
    min-height: 25rem;
  }

  .space-premium-ctr-landing h1 {
    font-size: 2rem;
  }

  .space-premium-ctr-landing h2 {
    font-size: 1rem;
  }

  .price-ctr {
    padding-top: 0;
    padding-bottom: 0;
  }

  .price-ctr p {
    font-size: 1.5rem;
  }

  .space-premium-ctr-landing div p span {
    font-size: 3rem;
  }

  .space-premium-ctr-landing ul {
    padding-left: 1.5rem;
  }

  .space-premium-ctr-landing p:nth-child(5) {
    padding-left: 1.5rem;
  }
}

@media only screen and (max-width: 280px) {
  .space-premium-ctr-landing h1 {
    margin-top: -1rem;
  }

  .space-premium-ctr-landing div p span {
    font-size: 2.5rem;
  }

  .price-ctr p {
    font-size: 1rem;
  }

  .space-premium-ctr-landing ul {
    padding-left: 0;
  }

  .space-premium-ctr-landing p:nth-child(5) {
    padding-left: 0;
  }
}