// ALL OF OUR COLOUR VARIABLES

$color-primary-blue: #003468;
$color-primary-red: #D91D1D;
$color-secondary-blue: #003061;
$color-secondary-red: #AB2F3B;
$color-info: #2091b6;
$color-warning: #dcaf08;
$color-success: #0fad46;
$color-danger: $color-primary-red;

// Page colours

$white: #fff;
$accent: #E9F5FF;
$color-darkblue: #2E3A46;
$color-darkgrey: #4F4F4F;
$color-grey: #667281;
$color-lightgrey: #A9AFB8;
$color-greyishblue: #F7FBFD;
$color-greyishblue-dark: #ECF3F8;
$color-greyishblue-light: #D5DAE1;

// Screen sizes

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Font weights

$light: 300;
$regular: 400;
$medium: 500;

// Font sizes

$body-sm: 0.875rem;
$body-md: 1rem;
$body-lg: 1.125rem;
$subheading: 1.5rem;
$heading: 2.25rem;

// Button / Alert / Badge types

$colors: (
  'primary': $color-primary-blue,
  'secondary': $color-primary-red,
  'success': $color-success,
  'info': $color-info,
  'warning': $color-warning,
  'danger': $color-danger,
  'cancel': $color-grey,
);

// Margin

$vertical-spacing-smol: 1.5rem;
$vertical-spacing-big: 2rem;
$sizes: 12;

// Container widths

$semi: 50%;
$full: 100%;


