@import '../../sassStyles/global-imports';

.tab-list {
  border-bottom: 1px solid $color-lightgrey;
  padding-left: 0;
  display: flex;
  margin-bottom: $vertical-spacing-big;
  margin-top: $vertical-spacing-big;

  .fa-icon { //used a lot, how to make global?
    width: 1.2em !important;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.375em;
    margin-bottom: 0.2em;
  }

    .nav-link {
      display: inline-block;
      list-style: none;
      padding: 0.5rem 0.2rem;
      color: $color-grey;
      font-size: 0.8rem;


      @include mdMinBreakPoint {
        font-size: initial;
        padding: 0.5rem 0.75rem;

      }
    }

    .active {
      color: $color-primary-blue;
      border-bottom: solid $color-primary-blue;
      border-width: 0 0 3px 0;
      margin-bottom: -1px;
    }
}

.tab-list > .nav-link:not(:first-child) { margin-left: 1.25rem;}




