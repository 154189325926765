@import '../../sassStyles/global-imports';

.lnk {
  color: $color-primary-red;
  text-decoration: underline;
  font-weight: $regular;
  font-size: $body-sm;
}

.lnk:hover, .lnk:active {
  color: $color-secondary-red;
  background: transparent;
  text-decoration: underline;
}

.lnk-btn {
  display: inline-block;
  padding: 0.625em 0.750em;
  font-weight: $regular;
  border-radius: 0.3125em;
  cursor:pointer;
  width: 100%;
  font-size: $body-md;
  text: {
    decoration: none;
    align: center;
  }

  @include trans(0.4s ease-in-out);

  @include smMinBreakPoint {
    width: initial;
  }

  &:active, &:hover {
    text-decoration: none;
  }
}

