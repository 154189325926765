@import '../../sassStyles/global-imports';

.location-tabs {
  margin: 1rem 0;

  .nav-item a {
    color: $color-grey;
    font-size: $body-sm;
    &:hover {
      color:$color-primary-blue;
    }
  }

    .nav-link.active {
      color: $color-primary-blue;
    }
}